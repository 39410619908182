<template>
  <div>
    <!--左侧菜单栏-->
    <a-col :span="4">
      <a-menu
        style="min-height: 400px"
        mode="vertical"
        :default-selected-keys="activeMenuTabKeys"
        v-if="isShowMenu('agreements')"
      >
        <a-menu-item
          v-for="item in showHasPermissionMenus"
          :key="item.routeName"
        >
          <router-link :to="{ name: item.routeName }">
            <a-icon :type="item.iconType" />
            {{ item.menuName }}
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-col>
    <!--内容-->
    <a-col :span="20">
      <div class="simiot-vertical-tab-content">
        <router-view />
      </div>
    </a-col>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgreementsTypeTab',
  data() {
    return {
      activeMenuTabKeys: [],
      allMenus: [
        {
          routeName: 'platform_service_agreements',
          menuName: '平台服务协议',
          iconType: 'file',
          permission: 'agreements/platform_service'
        },
        {
          routeName: 'card_agreements',
          menuName: '物联网卡协议',
          iconType: 'file',
          permission: 'agreements/card'
        }
      ],
      showHasPermissionMenus: []
    }
  },
  created() {
    this.fetchShowHasPermissionMenus()
    this.activeMenuTabKeys = [this.$route.name]
  },
  methods: {
    fetchShowHasPermissionMenus() {
      this.allMenus.forEach((value) => {
        if (this.isShowMenu(value.permission)) {
          this.showHasPermissionMenus.push(value)
        }
      })
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>
